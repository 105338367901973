import React from 'react';
import Placeholder from '../../../images/placeholder.svg';
import Image, { ImageData } from '../../Image';
import { Sizes } from '../../../constants/Image';
import LocalizedLink from '../../LocalizedLink';
import './styles/NewsItem.scss';

interface Props {
  data?: {
    slug: string;
    title: string;
    content: string;
    created_at?: string;
    createdAt?: string;
    categories: { title: string }[];
    preview: string;
    cover: ImageData;
  };
}

export default class NewsItem extends React.Component<Props> {
  categories() {
    const { data } = this.props;
    const categoriesMap = data.categories.map((cat, index) => {
      return <span key={index}>{cat.title}</span>;
    });
    return categoriesMap;
  }

  itemImage() {
    const { data } = this.props;
    let image = <img src={Placeholder} alt="" />;
    if (data.cover) {
      image = <Image image={data.cover} defaultSize={Sizes.W800} />;
    }
    return image;
  }

  render() {
    const { data } = this.props;
    return (
      <LocalizedLink to={`/blogi/${data.slug}`} className="component-news-item">
        <div className="component-news-item__image">{this.itemImage()}</div>
        <div className="component-news-item__info">
          <p className="component-news-item__info__date">
            {data.created_at || data.createdAt}
            {this.categories()}
          </p>
          <h2 className="component-news-item__info__title">{data.title}</h2>
          <p className="component-news-item__info__preview">{data.preview}</p>
        </div>
      </LocalizedLink>
    );
  }
}
