import React from 'react';
import { useParams } from 'react-router-dom';
import NewsSingle from './NewsSingle';

const NewsSingleIndex = () => {
  const { slug } = useParams<{ slug: string }>();
  return <NewsSingle slug={slug} limit={3} />;
};

export default NewsSingleIndex;
