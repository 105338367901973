import React, { useEffect } from 'react';
import LocalizedLink from '../../../components/LocalizedLink';
import Translate from '../../../components/Translate/Translate';
import NewsItem from './NewsItem';
import { LatestPostsItem } from './NewsSingle';

interface Props {
  data?: LatestPostsItem[];
}

export default function LatestNews({ data }: Props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const newsLatestMap = data.map((item, index) => {
    return <NewsItem key={index} data={item} />;
  });

  return (
    <div className="pt-12 pb-2.5 lg:pt-20">
      <div className="flex flex-col grow-0 shrink-0 basis-full pb-5 lg:flex-row lg:items-center">
        <h3 className="flex-1">
          <Translate component="blog" keyword="read-more" />
        </h3>
        <LocalizedLink
          className="max-w-36 font-axi-bold py-4 px-4 bg-white border rounded border-solid border-rgrey-light hidden lg:inline-flex transition-all duration-100 hover:text-white hover:bg-rgreen"
          to="/blogi"
          onClick={scrollToTop}
        >
          <Translate component="blog" keyword="all-posts" />
        </LocalizedLink>
      </div>
      <div className="flex flex-wrap my-0 -mx-2.5">{newsLatestMap}</div>
    </div>
  );
}
