import React, { useEffect, useState } from 'react';
import Translate from '../../Translate/Translate';
import Placeholder from '../../../images/placeholder.svg';
import Image from '../../Image';
import { Sizes } from '../../../constants/Image';
import LocalizedLink from '../../LocalizedLink';
import ErrorBanner from '../../../components/404';
import LatestNews from './LatestNews';
import { scrollToTop } from '../../../services/Util';
import { gql, useQuery } from '@apollo/client';
import Loader from '../../Loader/Loader';
import './styles/NewsSingle.scss';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import AuthorImage from '../../../images/author-img.svg';
import parse from 'html-react-parser';

const GET_POSTS = gql`
  query Posts($ignoredPostSlug: String, $limit: Int, $postSlug: String) {
    latestPosts(ignoredPostSlug: $ignoredPostSlug, limit: $limit) {
      categories {
        id
        slug
        title
      }
      content
      cover {
        cdn_url
        urls {
          W1000
          W800
        }
      }
      createdAt
      id
      preview
      slug
      title
    }
    singlePost(postSlug: $postSlug) {
      author
      authorExtra
      categories {
        id
        slug
        title
      }
      content
      cover {
        cdn_url
        urls {
          W1000
        }
      }
      createdAt
      id
      preview
      slug
      title
    }
  }
`;

export type LatestPostsItem = {
  categories: {
    id: string;
    slug: string;
    title: string;
  }[];
  content: string;
  cover: {
    cdn_url: string;
    urls: {
      W1000: string;
      W800: string;
    };
  };
  createdAt: string;
  id: string;
  preview: string;
  slug: string;
  title: string;
};

type SinglePostItem = {
  author: string;
  authorExtra: string;
  categories: {
    id: string;
    slug: string;
    title: string;
  }[];
  content: string;
  cover: {
    cdn_url: string;
    urls: {
      W1000: string;
    };
  };
  createdAt: string;
  id: string;
  preview: string;
  slug: string;
  title: string;
};

type Props = {
  limit?: number;
  slug: string;
};

export default function NewsSingle({ limit, slug }: Props) {
  const { loading, error, data } = useQuery<{
    latestPosts: LatestPostsItem[];
    singlePost: SinglePostItem;
  }>(GET_POSTS, {
    variables: { ignoredPostSlug: slug, limit, postSlug: slug },
  });
  const [currentNewsSlug, setCurrentNewsSlug] = useState('');

  useEffect(() => {
    if (!loading && !error) {
      loadSingle();
    }
  }, [loading, error, data]);

  if (loading) {
    return (
      <div className="flex justify-center m-10">
        <Loader />
      </div>
    );
  }

  if (error) {
    return (
      <ErrorBanner
        titleKeyword="blog"
        buttonLink="/blogi"
        buttonKeyword="back-blog"
      />
    );
  }

  const latest = data.latestPosts;
  const single = data.singlePost;

  const loadSingle = async () => {
    if (slug === currentNewsSlug) {
      return;
    }
    setCurrentNewsSlug(slug);
    scrollToTop();
  };

  const bannerImage = () => {
    let image = <img src={Placeholder} alt="" />;
    if (single.cover.urls) {
      image = (
        <Image
          image={single.cover}
          defaultSize={Sizes.W1000}
          className="rounded-lg"
        />
      );
    }
    return (
      <div className="relative">
        {image}
        <div className="absolute inset-0 bg-black opacity-20 rounded-lg"></div>
      </div>
    );
  };

  return (
    <div className="pt-2.5 lg:pt-10 pb-0 px-0">
      <div className="max-w-7xl mx-auto my-0">
        <LocalizedLink
          className="lg:hidden font-axi-bold bg-transparent text-rgrey relative inline-flex px-0 py-4 hover:text-black hover:transition-all hover:duration-100 items-center"
          to="/blogi"
        >
          <ChevronLeftIcon className="mr-2 h-5 w-5" />
          <Translate component="product" keyword="back" />
        </LocalizedLink>
        <div className="text-left pb-2.5 lg:flex lg:text-center">
          <h1 className="text-3xl leading-10 lg:text-6xl lg:leading-[70px] lg:max-w-5xl lg:mx-auto lg:my-0">
            {single.title}
          </h1>
        </div>
        <div className="flex justify-between flex-col lg:flex-row flex-wrap">
          <LocalizedLink
            className="hidden font-axi-bold bg-transparent text-rgrey relative lg:inline-flex px-0 py-4 hover:text-black hover:transition-all hover:duration-100 items-center"
            to="/blogi"
          >
            <ChevronLeftIcon className="mr-2 h-5 w-5" />
            <Translate component="product" keyword="back" />
          </LocalizedLink>
          <div className="flex flex-wrap lg:items-center lg:ml-auto lg:flex-nowrap">
            <span className="w-full mr-5 pt-1 pb-2.5 text-rgrey lg:pb-0 lg:w-auto">
              {single.createdAt}
            </span>
            <div className="flex flex-wrap w-full pb-8 lg:pb-0 lg:w-auto">
              {single.categories.map((cat, index) => (
                <LocalizedLink
                  key={cat.slug}
                  className="font-axi-bold transition-all duration-100 rounded border text-black mr-2.5 px-3.5 py-1.5 border-solid border-rgrey-light last:mr-0 hover:border hover:bg-black hover:text-white hover:border-solid hover:border-black"
                  to={`/blogi?category=${cat.slug}`}
                >
                  <p key={index}>{cat.title}</p>
                </LocalizedLink>
              ))}
            </div>
          </div>
        </div>

        {bannerImage()}
        <div className="page-news-single__content items-center max-w-4xl mt-8 lg:mt-15 mx-auto my-0">
          {parse(single.content)}
        </div>
        <div className="flex flex-col items-center max-w-4xl border box-border lg:flex-row mx-auto my-15 lg:py-5 p-8 rounded-lg border-solid border-rgrey-light">
          <div className="max-w-20 max-h-20 mb-5 lg:mr-5 lg:mb-0">
            <img src={AuthorImage} alt="author" />
          </div>
          <div className="text-center mb-6 lg:text-left lg:mb-0">
            <p className="text-base leading-7 font-axi-bold mb-0">
              {single.author}
            </p>
            <span className="text-rgrey">{single.authorExtra}</span>
          </div>
        </div>
      </div>
      {!latest.length ? null : <LatestNews data={latest} />}
    </div>
  );
}
